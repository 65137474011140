/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}

#userForm {
  padding: 20px;
  max-width: 400px;
  margin: 30px auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#userForm img {
  display: block;
  margin: 0 auto 20px;
}

#userForm label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

#userForm input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#userForm .form-check {
  margin-bottom: 15px;
}

#userForm .form-check-label {
  margin-left: 5px;
}

#userForm button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

#userForm button:hover {
  background-color: #0056b3;
}

#map {
  flex: 1 1 80%; /* Occupy the remaining 80% of the screen */
  display: block;
  width: 100%;
  height: 60vh;
}

#control_wrapper {
  display: none;
  text-align: center;
}

#control_wrapper button {
  margin: 5px;
  padding: 8px 15px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

#control_wrapper button:hover {
  background-color: #5a6268;
}

#instructions {
  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 10px;
  display: none;
}
#addressSuggestions {
  list-style-type: none;
  padding: 0;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* incase we want our own markers */
.origin-marker,
.destination-marker,
.current-marker {
  width: 30px;
  height: 30px;
  background-color: red; /* Change color as needed */
  border-radius: 50%;
  cursor: pointer;
}

/* Differentiate markers by class */
.origin-marker {
  background-color: green;
}

.destination-marker {
  background-color: red;
}

.current-marker {
  background-color: blue;
}